<template>
<div class='app-container'>
  <div class="img-style">
    <img src="../../assets/404.png" alt="404" style='max-width:100%;margin: auto;display: block;' >
    <div style="position: absolute;left:50%;top:40%;transform: translate(-50%,-50%);">
      <button  type="primary" round @click="handleGoMain" style="background:#fff;border: 2px solid #94BFF3;color: #94BFF3;border-radius: 15px;padding: 2px 14px;font-size: 15px;"><router-link to="/">返回首页</router-link></button>
    </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
};
</script>
<style scoped>
.app-container {
    width: 80%;
    margin: 120px auto;
  }

  .img-style {
    margin:0 auto;
    position: relative;
  }
</style>